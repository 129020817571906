import Statsig from 'statsig-js';

export default defineNuxtPlugin(async(nuxtApp) => {
  const userStore = useUserStore();
  const { userData, } = storeToRefs(userStore);
  const { getUserData, } = userStore;

  const { $rollbar, } = useNuxtApp();

  function getEnvironment() {
    switch (nuxtApp.$config.public.ENV_NAME.toLowerCase()) {
      case 'prod':
        return 'production';
      case 'qa':
        return 'staging';
      default:
        return 'development';
    }
  }
  try {
    await Statsig.initialize(nuxtApp.$config.public.STATSIG_CLIENT_SDK_KEY, {
      userID: userData.value?.id || 0,
    }, {
      environment: { tier: getEnvironment(), },
    });
  } catch (error) {
    $rollbar.error('Error initializing Statsig:', error);
  }

  nuxtApp.provide('statsig', Statsig);

  userStore.$subscribe(async(_, state) => {
    if (state.userData?.id) {
      await Statsig.updateUser({ userID: state.userData.id, });
    }
  });

  async function isFeatureFlagged(feature) {
    if (typeof feature !== 'string' || !feature.trim()) {
      console.warn('Invalid feature flag:', feature);
      return false;
    }

    try {
      // Keep check as need to confirm/get userdata before checking feature flag
      // This is important for page loads such as post login
      if (!userData.value?.id) {
        await getUserData();
      }

      return Statsig.checkGate(feature);
    } catch (error) {
      $rollbar.error('Error checking feature flag:', error);
      return false;
    }
  }

  return {
    provide: {
      isFeatureFlagged,
    },
  };
});
