import { default as _winnersb5FCmKLLeFMeta } from "/home/circleci/project/projects/main/pages/_winners.vue?macro=true";
import { default as _91id_937fu1CzYNyMMeta } from "/home/circleci/project/projects/main/pages/-play/[id].vue?macro=true";
import { default as indexqndKbLGqMKMeta } from "/home/circleci/project/projects/main/pages/-winbox/index.vue?macro=true";
import { default as _91id_93N632eJDXVLMeta } from "/home/circleci/project/projects/main/pages/[id].vue?macro=true";
import { default as _12b2LL4Vm7WMeta } from "/home/circleci/project/projects/main/pages/1.vue?macro=true";
import { default as _40420P1K61K8fMeta } from "/home/circleci/project/projects/main/pages/404.vue?macro=true";
import { default as confirm5AFcWcrYYOMeta } from "/home/circleci/project/projects/main/pages/account/alias/[token]/confirm.vue?macro=true";
import { default as games_45activityr8gs1eENwnMeta } from "/home/circleci/project/projects/main/pages/account/games-activity.vue?macro=true";
import { default as indexIj6ACbP0CUMeta } from "/home/circleci/project/projects/main/pages/account/index.vue?macro=true";
import { default as tipyDgpFsICUjMeta } from "/home/circleci/project/projects/main/pages/account/tip.vue?macro=true";
import { default as index6ZbiFPhNd0Meta } from "/home/circleci/project/projects/main/pages/activity/index.vue?macro=true";
import { default as amoeAdEhnTceRdMeta } from "/home/circleci/project/projects/main/pages/amoe.vue?macro=true";
import { default as indexkWKx4VQFW1Meta } from "/home/circleci/project/projects/main/pages/auth/index.vue?macro=true";
import { default as _91provider_93YoQln28vLEMeta } from "/home/circleci/project/projects/main/pages/auth/signin/[provider].vue?macro=true";
import { default as _91provider_9334OMbW9zdeMeta } from "/home/circleci/project/projects/main/pages/auth/signup/[provider].vue?macro=true";
import { default as bridgeq4bFV5HGLFMeta } from "/home/circleci/project/projects/main/pages/bridge.vue?macro=true";
import { default as _91id_93DZ6DiaijfKMeta } from "/home/circleci/project/projects/main/pages/collection/[id].vue?macro=true";
import { default as _91id_93x2mc47B2zOMeta } from "/home/circleci/project/projects/main/pages/competition/[id].vue?macro=true";
import { default as consent_45settingsbxsk2T1IM8Meta } from "/home/circleci/project/projects/main/pages/consent-settings.vue?macro=true";
import { default as contact_45us9BhSf1HmSFMeta } from "/home/circleci/project/projects/main/pages/contact-us.vue?macro=true";
import { default as cookie_45policykqcQgCfHMSMeta } from "/home/circleci/project/projects/main/pages/cookie-policy.vue?macro=true";
import { default as indexUMJrv7AgTqMeta } from "/home/circleci/project/projects/main/pages/faq/index.vue?macro=true";
import { default as indexhuBgWCrWdbMeta } from "/home/circleci/project/projects/main/pages/faq/rekt-details/index.vue?macro=true";
import { default as _91id_93vnGLFf6GqBMeta } from "/home/circleci/project/projects/main/pages/games/[id].vue?macro=true";
import { default as _91id_934CYQfdM6PUMeta } from "/home/circleci/project/projects/main/pages/games/collection/[id].vue?macro=true";
import { default as recentSyCWx3gpuIMeta } from "/home/circleci/project/projects/main/pages/games/collection/recent.vue?macro=true";
import { default as indexbFCsmiCxorMeta } from "/home/circleci/project/projects/main/pages/games/index.vue?macro=true";
import { default as kycgZhFxllrUpMeta } from "/home/circleci/project/projects/main/pages/games/kyc.vue?macro=true";
import { default as terms_45and_45conditionsrsSCrh4zINMeta } from "/home/circleci/project/projects/main/pages/games/terms-and-conditions.vue?macro=true";
import { default as gamesKasUhAGpUiMeta } from "/home/circleci/project/projects/main/pages/games.vue?macro=true";
import { default as gamesyV7JUbKBdVMeta } from "/home/circleci/project/projects/main/pages/history/games.vue?macro=true";
import { default as indexnBG6ZshL6TMeta } from "/home/circleci/project/projects/main/pages/history/index.vue?macro=true";
import { default as tradingMBTwrhHa0GMeta } from "/home/circleci/project/projects/main/pages/history/trading.vue?macro=true";
import { default as historyIL5FvY7G9MMeta } from "/home/circleci/project/projects/main/pages/history.vue?macro=true";
import { default as indexiFeDii3PUTMeta } from "/home/circleci/project/projects/main/pages/index.vue?macro=true";
import { default as _91id_93WCWWFT1qrcMeta } from "/home/circleci/project/projects/main/pages/live-games/[id].vue?macro=true";
import { default as indexxQLKTWpD7XMeta } from "/home/circleci/project/projects/main/pages/live-games/index.vue?macro=true";
import { default as live_45games8DSGp7E6UkMeta } from "/home/circleci/project/projects/main/pages/live-games.vue?macro=true";
import { default as maintenanceoRyhdGqA0VMeta } from "/home/circleci/project/projects/main/pages/maintenance.vue?macro=true";
import { default as million_45raceSVclMPUAubMeta } from "/home/circleci/project/projects/main/pages/million-race.vue?macro=true";
import { default as _91id_93kGocaVhp6yMeta } from "/home/circleci/project/projects/main/pages/minigames/[id].vue?macro=true";
import { default as notificationsswOndpOuLdMeta } from "/home/circleci/project/projects/main/pages/notifications.vue?macro=true";
import { default as signinEHpPJivlfGMeta } from "/home/circleci/project/projects/main/pages/one-time/signin.vue?macro=true";
import { default as signup1nGFBrDMonMeta } from "/home/circleci/project/projects/main/pages/one-time/signup.vue?macro=true";
import { default as privacy_45policy22bv3OO2aYMeta } from "/home/circleci/project/projects/main/pages/privacy-policy.vue?macro=true";
import { default as _91id_9340GARu8oSwMeta } from "/home/circleci/project/projects/main/pages/promotions/[id].vue?macro=true";
import { default as indexG0KBcIbMnnMeta } from "/home/circleci/project/projects/main/pages/promotions/index.vue?macro=true";
import { default as meta_45winnersMAASvcbdEbMeta } from "/home/circleci/project/projects/main/pages/promotions/meta-winners.vue?macro=true";
import { default as salon_45rougeRSZb4DI7fLMeta } from "/home/circleci/project/projects/main/pages/promotions/salon-rouge.vue?macro=true";
import { default as sportsm1mvy3fg3YMeta } from "/home/circleci/project/projects/main/pages/promotions/sports.vue?macro=true";
import { default as vip_45transfer_45season3ldpv8uOjOiMeta } from "/home/circleci/project/projects/main/pages/promotions/vip-transfer-season3.vue?macro=true";
import { default as winbackUFBYDjs7DHMeta } from "/home/circleci/project/projects/main/pages/promotions/winback.vue?macro=true";
import { default as xmas_45race_45booster7W9B6EyeO3Meta } from "/home/circleci/project/projects/main/pages/promotions/xmas-race-booster.vue?macro=true";
import { default as indexwBuTukieUKMeta } from "/home/circleci/project/projects/main/pages/providers/index.vue?macro=true";
import { default as _91_46_46_46slug_93brk0G5RauwMeta } from "/home/circleci/project/projects/main/pages/referral/[...slug].vue?macro=true";
import { default as referralsytd46JNFwaMeta } from "/home/circleci/project/projects/main/pages/referrals.vue?macro=true";
import { default as refundsW2KiPV4zBHMeta } from "/home/circleci/project/projects/main/pages/refunds.vue?macro=true";
import { default as responsible_45gamblingkHrH49nFSCMeta } from "/home/circleci/project/projects/main/pages/responsible-gambling.vue?macro=true";
import { default as self_45exclusion_45policyLcYpniCoCuMeta } from "/home/circleci/project/projects/main/pages/self-exclusion-policy.vue?macro=true";
import { default as avatar9006ufSLE4Meta } from "/home/circleci/project/projects/main/pages/signup/avatar.vue?macro=true";
import { default as registerhoQiI7lij7Meta } from "/home/circleci/project/projects/main/pages/signup/register.vue?macro=true";
import { default as verificationAgbZPa0nt4Meta } from "/home/circleci/project/projects/main/pages/signup/verification.vue?macro=true";
import { default as callback8OT7tj7rZHMeta } from "/home/circleci/project/projects/main/pages/social/connect/[provider]/callback.vue?macro=true";
import { default as indexK5W8p2AsKcMeta } from "/home/circleci/project/projects/main/pages/sports/index.vue?macro=true";
import { default as terms_45and_45conditions3zS8mdD01YMeta } from "/home/circleci/project/projects/main/pages/sports/terms-and-conditions.vue?macro=true";
import { default as terms_45and_45conditionsQBgL6pni1YMeta } from "/home/circleci/project/projects/main/pages/terms-and-conditions.vue?macro=true";
import { default as _91asset_93pCeLcVeAgIMeta } from "/home/circleci/project/projects/main/pages/trading/[asset].vue?macro=true";
import { default as underage_45gaming_45policy3UYdRdU2PcMeta } from "/home/circleci/project/projects/main/pages/underage-gaming-policy.vue?macro=true";
import { default as unsubscribemVivJBiRyaMeta } from "/home/circleci/project/projects/main/pages/unsubscribe.vue?macro=true";
import { default as walletGEblUt4wKPMeta } from "/home/circleci/project/projects/main/pages/wallet.vue?macro=true";
import { default as weekly_45raceXcxKUV4DFDMeta } from "/home/circleci/project/projects/main/pages/weekly-race.vue?macro=true";
import { default as winnerszYDFTYGaZ0Meta } from "/home/circleci/project/projects/main/pages/winners.vue?macro=true";
export default [
  {
    name: _winnersb5FCmKLLeFMeta?.name ?? "_winners",
    path: _winnersb5FCmKLLeFMeta?.path ?? "/_winners",
    meta: _winnersb5FCmKLLeFMeta || {},
    alias: _winnersb5FCmKLLeFMeta?.alias || [],
    redirect: _winnersb5FCmKLLeFMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/_winners.vue").then(m => m.default || m)
  },
  {
    name: _91id_937fu1CzYNyMMeta?.name ?? "-play-id",
    path: _91id_937fu1CzYNyMMeta?.path ?? "/-play/:id()",
    meta: _91id_937fu1CzYNyMMeta || {},
    alias: _91id_937fu1CzYNyMMeta?.alias || [],
    redirect: _91id_937fu1CzYNyMMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/-play/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqndKbLGqMKMeta?.name ?? "-winbox",
    path: indexqndKbLGqMKMeta?.path ?? "/-winbox",
    meta: indexqndKbLGqMKMeta || {},
    alias: indexqndKbLGqMKMeta?.alias || [],
    redirect: indexqndKbLGqMKMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/-winbox/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93N632eJDXVLMeta?.name ?? "id",
    path: _91id_93N632eJDXVLMeta?.path ?? "/:id()",
    meta: _91id_93N632eJDXVLMeta || {},
    alias: _91id_93N632eJDXVLMeta?.alias || [],
    redirect: _91id_93N632eJDXVLMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: _12b2LL4Vm7WMeta?.name ?? "1",
    path: _12b2LL4Vm7WMeta?.path ?? "/1",
    meta: _12b2LL4Vm7WMeta || {},
    alias: _12b2LL4Vm7WMeta?.alias || [],
    redirect: _12b2LL4Vm7WMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/1.vue").then(m => m.default || m)
  },
  {
    name: _40420P1K61K8fMeta?.name ?? "404",
    path: _40420P1K61K8fMeta?.path ?? "/404",
    meta: _40420P1K61K8fMeta || {},
    alias: _40420P1K61K8fMeta?.alias || [],
    redirect: _40420P1K61K8fMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/404.vue").then(m => m.default || m)
  },
  {
    name: confirm5AFcWcrYYOMeta?.name ?? "account-alias-token-confirm",
    path: confirm5AFcWcrYYOMeta?.path ?? "/account/alias/:token()/confirm",
    meta: confirm5AFcWcrYYOMeta || {},
    alias: confirm5AFcWcrYYOMeta?.alias || [],
    redirect: confirm5AFcWcrYYOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/account/alias/[token]/confirm.vue").then(m => m.default || m)
  },
  {
    name: games_45activityr8gs1eENwnMeta?.name ?? "account-games-activity",
    path: games_45activityr8gs1eENwnMeta?.path ?? "/account/games-activity",
    meta: games_45activityr8gs1eENwnMeta || {},
    alias: games_45activityr8gs1eENwnMeta?.alias || [],
    redirect: games_45activityr8gs1eENwnMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/account/games-activity.vue").then(m => m.default || m)
  },
  {
    name: indexIj6ACbP0CUMeta?.name ?? "account",
    path: indexIj6ACbP0CUMeta?.path ?? "/account",
    meta: indexIj6ACbP0CUMeta || {},
    alias: indexIj6ACbP0CUMeta?.alias || [],
    redirect: indexIj6ACbP0CUMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: tipyDgpFsICUjMeta?.name ?? "account-tip",
    path: tipyDgpFsICUjMeta?.path ?? "/account/tip",
    meta: tipyDgpFsICUjMeta || {},
    alias: tipyDgpFsICUjMeta?.alias || [],
    redirect: tipyDgpFsICUjMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/account/tip.vue").then(m => m.default || m)
  },
  {
    name: index6ZbiFPhNd0Meta?.name ?? "activity",
    path: index6ZbiFPhNd0Meta?.path ?? "/activity",
    meta: index6ZbiFPhNd0Meta || {},
    alias: index6ZbiFPhNd0Meta?.alias || [],
    redirect: index6ZbiFPhNd0Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/activity/index.vue").then(m => m.default || m)
  },
  {
    name: amoeAdEhnTceRdMeta?.name ?? "amoe",
    path: amoeAdEhnTceRdMeta?.path ?? "/amoe",
    meta: amoeAdEhnTceRdMeta || {},
    alias: amoeAdEhnTceRdMeta?.alias || [],
    redirect: amoeAdEhnTceRdMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/amoe.vue").then(m => m.default || m)
  },
  {
    name: indexkWKx4VQFW1Meta?.name ?? "auth",
    path: indexkWKx4VQFW1Meta?.path ?? "/auth",
    meta: indexkWKx4VQFW1Meta || {},
    alias: indexkWKx4VQFW1Meta?.alias || [],
    redirect: indexkWKx4VQFW1Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: _91provider_93YoQln28vLEMeta?.name ?? "auth-signin-provider",
    path: _91provider_93YoQln28vLEMeta?.path ?? "/auth/signin/:provider()",
    meta: _91provider_93YoQln28vLEMeta || {},
    alias: _91provider_93YoQln28vLEMeta?.alias || [],
    redirect: _91provider_93YoQln28vLEMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/auth/signin/[provider].vue").then(m => m.default || m)
  },
  {
    name: _91provider_9334OMbW9zdeMeta?.name ?? "auth-signup-provider",
    path: _91provider_9334OMbW9zdeMeta?.path ?? "/auth/signup/:provider()",
    meta: _91provider_9334OMbW9zdeMeta || {},
    alias: _91provider_9334OMbW9zdeMeta?.alias || [],
    redirect: _91provider_9334OMbW9zdeMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/auth/signup/[provider].vue").then(m => m.default || m)
  },
  {
    name: bridgeq4bFV5HGLFMeta?.name ?? "bridge",
    path: bridgeq4bFV5HGLFMeta?.path ?? "/bridge",
    meta: bridgeq4bFV5HGLFMeta || {},
    alias: bridgeq4bFV5HGLFMeta?.alias || [],
    redirect: bridgeq4bFV5HGLFMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/bridge.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DZ6DiaijfKMeta?.name ?? "collection-id",
    path: _91id_93DZ6DiaijfKMeta?.path ?? "/collection/:id()",
    meta: _91id_93DZ6DiaijfKMeta || {},
    alias: _91id_93DZ6DiaijfKMeta?.alias || [],
    redirect: _91id_93DZ6DiaijfKMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/collection/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93x2mc47B2zOMeta?.name ?? "competition-id",
    path: _91id_93x2mc47B2zOMeta?.path ?? "/competition/:id()",
    meta: _91id_93x2mc47B2zOMeta || {},
    alias: _91id_93x2mc47B2zOMeta?.alias || [],
    redirect: _91id_93x2mc47B2zOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/competition/[id].vue").then(m => m.default || m)
  },
  {
    name: consent_45settingsbxsk2T1IM8Meta?.name ?? "consent-settings",
    path: consent_45settingsbxsk2T1IM8Meta?.path ?? "/consent-settings",
    meta: consent_45settingsbxsk2T1IM8Meta || {},
    alias: consent_45settingsbxsk2T1IM8Meta?.alias || [],
    redirect: consent_45settingsbxsk2T1IM8Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/consent-settings.vue").then(m => m.default || m)
  },
  {
    name: contact_45us9BhSf1HmSFMeta?.name ?? "contact-us",
    path: contact_45us9BhSf1HmSFMeta?.path ?? "/contact-us",
    meta: contact_45us9BhSf1HmSFMeta || {},
    alias: contact_45us9BhSf1HmSFMeta?.alias || [],
    redirect: contact_45us9BhSf1HmSFMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policykqcQgCfHMSMeta?.name ?? "cookie-policy",
    path: cookie_45policykqcQgCfHMSMeta?.path ?? "/cookie-policy",
    meta: cookie_45policykqcQgCfHMSMeta || {},
    alias: cookie_45policykqcQgCfHMSMeta?.alias || [],
    redirect: cookie_45policykqcQgCfHMSMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexUMJrv7AgTqMeta?.name ?? "faq",
    path: indexUMJrv7AgTqMeta?.path ?? "/faq",
    meta: indexUMJrv7AgTqMeta || {},
    alias: indexUMJrv7AgTqMeta?.alias || [],
    redirect: indexUMJrv7AgTqMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexhuBgWCrWdbMeta?.name ?? "faq-rekt-details",
    path: indexhuBgWCrWdbMeta?.path ?? "/faq/rekt-details",
    meta: indexhuBgWCrWdbMeta || {},
    alias: indexhuBgWCrWdbMeta?.alias || [],
    redirect: indexhuBgWCrWdbMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/faq/rekt-details/index.vue").then(m => m.default || m)
  },
  {
    path: gamesKasUhAGpUiMeta?.path ?? "/games",
    children: [
  {
    name: _91id_93vnGLFf6GqBMeta?.name ?? "games-id",
    path: _91id_93vnGLFf6GqBMeta?.path ?? ":id()",
    meta: _91id_93vnGLFf6GqBMeta || {},
    alias: _91id_93vnGLFf6GqBMeta?.alias || [],
    redirect: _91id_93vnGLFf6GqBMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/games/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_934CYQfdM6PUMeta?.name ?? "games-collection-id",
    path: _91id_934CYQfdM6PUMeta?.path ?? "collection/:id()",
    meta: _91id_934CYQfdM6PUMeta || {},
    alias: _91id_934CYQfdM6PUMeta?.alias || [],
    redirect: _91id_934CYQfdM6PUMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/games/collection/[id].vue").then(m => m.default || m)
  },
  {
    name: recentSyCWx3gpuIMeta?.name ?? "games-collection-recent",
    path: recentSyCWx3gpuIMeta?.path ?? "collection/recent",
    meta: recentSyCWx3gpuIMeta || {},
    alias: recentSyCWx3gpuIMeta?.alias || [],
    redirect: recentSyCWx3gpuIMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/games/collection/recent.vue").then(m => m.default || m)
  },
  {
    name: indexbFCsmiCxorMeta?.name ?? "games",
    path: indexbFCsmiCxorMeta?.path ?? "",
    meta: indexbFCsmiCxorMeta || {},
    alias: indexbFCsmiCxorMeta?.alias || [],
    redirect: indexbFCsmiCxorMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: kycgZhFxllrUpMeta?.name ?? "games-kyc",
    path: kycgZhFxllrUpMeta?.path ?? "kyc",
    meta: kycgZhFxllrUpMeta || {},
    alias: kycgZhFxllrUpMeta?.alias || [],
    redirect: kycgZhFxllrUpMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/games/kyc.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsrsSCrh4zINMeta?.name ?? "games-terms-and-conditions",
    path: terms_45and_45conditionsrsSCrh4zINMeta?.path ?? "terms-and-conditions",
    meta: terms_45and_45conditionsrsSCrh4zINMeta || {},
    alias: terms_45and_45conditionsrsSCrh4zINMeta?.alias || [],
    redirect: terms_45and_45conditionsrsSCrh4zINMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/games/terms-and-conditions.vue").then(m => m.default || m)
  }
],
    name: gamesKasUhAGpUiMeta?.name ?? undefined,
    meta: gamesKasUhAGpUiMeta || {},
    alias: gamesKasUhAGpUiMeta?.alias || [],
    redirect: gamesKasUhAGpUiMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/games.vue").then(m => m.default || m)
  },
  {
    path: historyIL5FvY7G9MMeta?.path ?? "/history",
    children: [
  {
    name: gamesyV7JUbKBdVMeta?.name ?? "history-games",
    path: gamesyV7JUbKBdVMeta?.path ?? "games",
    meta: gamesyV7JUbKBdVMeta || {},
    alias: gamesyV7JUbKBdVMeta?.alias || [],
    redirect: gamesyV7JUbKBdVMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/history/games.vue").then(m => m.default || m)
  },
  {
    name: indexnBG6ZshL6TMeta?.name ?? "history",
    path: indexnBG6ZshL6TMeta?.path ?? "",
    meta: indexnBG6ZshL6TMeta || {},
    alias: indexnBG6ZshL6TMeta?.alias || [],
    redirect: indexnBG6ZshL6TMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: tradingMBTwrhHa0GMeta?.name ?? "history-trading",
    path: tradingMBTwrhHa0GMeta?.path ?? "trading",
    meta: tradingMBTwrhHa0GMeta || {},
    alias: tradingMBTwrhHa0GMeta?.alias || [],
    redirect: tradingMBTwrhHa0GMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/history/trading.vue").then(m => m.default || m)
  }
],
    name: historyIL5FvY7G9MMeta?.name ?? undefined,
    meta: historyIL5FvY7G9MMeta || {},
    alias: historyIL5FvY7G9MMeta?.alias || [],
    redirect: historyIL5FvY7G9MMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/history.vue").then(m => m.default || m)
  },
  {
    name: indexiFeDii3PUTMeta?.name ?? "index",
    path: indexiFeDii3PUTMeta?.path ?? "/",
    meta: indexiFeDii3PUTMeta || {},
    alias: indexiFeDii3PUTMeta?.alias || [],
    redirect: indexiFeDii3PUTMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/index.vue").then(m => m.default || m)
  },
  {
    path: live_45games8DSGp7E6UkMeta?.path ?? "/live-games",
    children: [
  {
    name: _91id_93WCWWFT1qrcMeta?.name ?? "live-games-id",
    path: _91id_93WCWWFT1qrcMeta?.path ?? ":id()",
    meta: _91id_93WCWWFT1qrcMeta || {},
    alias: _91id_93WCWWFT1qrcMeta?.alias || [],
    redirect: _91id_93WCWWFT1qrcMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/live-games/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxQLKTWpD7XMeta?.name ?? "live-games",
    path: indexxQLKTWpD7XMeta?.path ?? "",
    meta: indexxQLKTWpD7XMeta || {},
    alias: indexxQLKTWpD7XMeta?.alias || [],
    redirect: indexxQLKTWpD7XMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/live-games/index.vue").then(m => m.default || m)
  }
],
    name: live_45games8DSGp7E6UkMeta?.name ?? undefined,
    meta: live_45games8DSGp7E6UkMeta || {},
    alias: live_45games8DSGp7E6UkMeta?.alias || [],
    redirect: live_45games8DSGp7E6UkMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/live-games.vue").then(m => m.default || m)
  },
  {
    name: maintenanceoRyhdGqA0VMeta?.name ?? "maintenance",
    path: maintenanceoRyhdGqA0VMeta?.path ?? "/maintenance",
    meta: maintenanceoRyhdGqA0VMeta || {},
    alias: maintenanceoRyhdGqA0VMeta?.alias || [],
    redirect: maintenanceoRyhdGqA0VMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: million_45raceSVclMPUAubMeta?.name ?? "million-race",
    path: million_45raceSVclMPUAubMeta?.path ?? "/million-race",
    meta: million_45raceSVclMPUAubMeta || {},
    alias: million_45raceSVclMPUAubMeta?.alias || [],
    redirect: million_45raceSVclMPUAubMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/million-race.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kGocaVhp6yMeta?.name ?? "minigames-id",
    path: _91id_93kGocaVhp6yMeta?.path ?? "/minigames/:id()",
    meta: _91id_93kGocaVhp6yMeta || {},
    alias: _91id_93kGocaVhp6yMeta?.alias || [],
    redirect: _91id_93kGocaVhp6yMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/minigames/[id].vue").then(m => m.default || m)
  },
  {
    name: notificationsswOndpOuLdMeta?.name ?? "notifications",
    path: notificationsswOndpOuLdMeta?.path ?? "/notifications",
    meta: notificationsswOndpOuLdMeta || {},
    alias: notificationsswOndpOuLdMeta?.alias || [],
    redirect: notificationsswOndpOuLdMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: signinEHpPJivlfGMeta?.name ?? "one-time-signin",
    path: signinEHpPJivlfGMeta?.path ?? "/one-time/signin",
    meta: signinEHpPJivlfGMeta || {},
    alias: signinEHpPJivlfGMeta?.alias || [],
    redirect: signinEHpPJivlfGMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/one-time/signin.vue").then(m => m.default || m)
  },
  {
    name: signup1nGFBrDMonMeta?.name ?? "one-time-signup",
    path: signup1nGFBrDMonMeta?.path ?? "/one-time/signup",
    meta: signup1nGFBrDMonMeta || {},
    alias: signup1nGFBrDMonMeta?.alias || [],
    redirect: signup1nGFBrDMonMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/one-time/signup.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy22bv3OO2aYMeta?.name ?? "privacy-policy",
    path: privacy_45policy22bv3OO2aYMeta?.path ?? "/privacy-policy",
    meta: privacy_45policy22bv3OO2aYMeta || {},
    alias: privacy_45policy22bv3OO2aYMeta?.alias || [],
    redirect: privacy_45policy22bv3OO2aYMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91id_9340GARu8oSwMeta?.name ?? "promotions-id",
    path: _91id_9340GARu8oSwMeta?.path ?? "/promotions/:id()",
    meta: _91id_9340GARu8oSwMeta || {},
    alias: _91id_9340GARu8oSwMeta?.alias || [],
    redirect: _91id_9340GARu8oSwMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/promotions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexG0KBcIbMnnMeta?.name ?? "promotions",
    path: indexG0KBcIbMnnMeta?.path ?? "/promotions",
    meta: indexG0KBcIbMnnMeta || {},
    alias: indexG0KBcIbMnnMeta?.alias || [],
    redirect: indexG0KBcIbMnnMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: meta_45winnersMAASvcbdEbMeta?.name ?? "promotions-meta-winners",
    path: meta_45winnersMAASvcbdEbMeta?.path ?? "/promotions/meta-winners",
    meta: meta_45winnersMAASvcbdEbMeta || {},
    alias: meta_45winnersMAASvcbdEbMeta?.alias || [],
    redirect: meta_45winnersMAASvcbdEbMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/promotions/meta-winners.vue").then(m => m.default || m)
  },
  {
    name: salon_45rougeRSZb4DI7fLMeta?.name ?? "promotions-salon-rouge",
    path: salon_45rougeRSZb4DI7fLMeta?.path ?? "/promotions/salon-rouge",
    meta: salon_45rougeRSZb4DI7fLMeta || {},
    alias: salon_45rougeRSZb4DI7fLMeta?.alias || [],
    redirect: salon_45rougeRSZb4DI7fLMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/promotions/salon-rouge.vue").then(m => m.default || m)
  },
  {
    name: sportsm1mvy3fg3YMeta?.name ?? "promotions-sports",
    path: sportsm1mvy3fg3YMeta?.path ?? "/promotions/sports",
    meta: sportsm1mvy3fg3YMeta || {},
    alias: sportsm1mvy3fg3YMeta?.alias || [],
    redirect: sportsm1mvy3fg3YMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/promotions/sports.vue").then(m => m.default || m)
  },
  {
    name: vip_45transfer_45season3ldpv8uOjOiMeta?.name ?? "promotions-vip-transfer-season3",
    path: vip_45transfer_45season3ldpv8uOjOiMeta?.path ?? "/promotions/vip-transfer-season3",
    meta: vip_45transfer_45season3ldpv8uOjOiMeta || {},
    alias: vip_45transfer_45season3ldpv8uOjOiMeta?.alias || [],
    redirect: vip_45transfer_45season3ldpv8uOjOiMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/promotions/vip-transfer-season3.vue").then(m => m.default || m)
  },
  {
    name: winbackUFBYDjs7DHMeta?.name ?? "promotions-winback",
    path: winbackUFBYDjs7DHMeta?.path ?? "/promotions/winback",
    meta: winbackUFBYDjs7DHMeta || {},
    alias: winbackUFBYDjs7DHMeta?.alias || [],
    redirect: winbackUFBYDjs7DHMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/promotions/winback.vue").then(m => m.default || m)
  },
  {
    name: xmas_45race_45booster7W9B6EyeO3Meta?.name ?? "promotions-xmas-race-booster",
    path: xmas_45race_45booster7W9B6EyeO3Meta?.path ?? "/promotions/xmas-race-booster",
    meta: xmas_45race_45booster7W9B6EyeO3Meta || {},
    alias: xmas_45race_45booster7W9B6EyeO3Meta?.alias || [],
    redirect: xmas_45race_45booster7W9B6EyeO3Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/promotions/xmas-race-booster.vue").then(m => m.default || m)
  },
  {
    name: indexwBuTukieUKMeta?.name ?? "providers",
    path: indexwBuTukieUKMeta?.path ?? "/providers",
    meta: indexwBuTukieUKMeta || {},
    alias: indexwBuTukieUKMeta?.alias || [],
    redirect: indexwBuTukieUKMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/providers/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93brk0G5RauwMeta?.name ?? "referral-slug",
    path: _91_46_46_46slug_93brk0G5RauwMeta?.path ?? "/referral/:slug(.*)*",
    meta: _91_46_46_46slug_93brk0G5RauwMeta || {},
    alias: _91_46_46_46slug_93brk0G5RauwMeta?.alias || [],
    redirect: _91_46_46_46slug_93brk0G5RauwMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/referral/[...slug].vue").then(m => m.default || m)
  },
  {
    name: referralsytd46JNFwaMeta?.name ?? "referrals",
    path: referralsytd46JNFwaMeta?.path ?? "/referrals",
    meta: referralsytd46JNFwaMeta || {},
    alias: referralsytd46JNFwaMeta?.alias || [],
    redirect: referralsytd46JNFwaMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: refundsW2KiPV4zBHMeta?.name ?? "refunds",
    path: refundsW2KiPV4zBHMeta?.path ?? "/refunds",
    meta: refundsW2KiPV4zBHMeta || {},
    alias: refundsW2KiPV4zBHMeta?.alias || [],
    redirect: refundsW2KiPV4zBHMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/refunds.vue").then(m => m.default || m)
  },
  {
    name: responsible_45gamblingkHrH49nFSCMeta?.name ?? "responsible-gambling",
    path: responsible_45gamblingkHrH49nFSCMeta?.path ?? "/responsible-gambling",
    meta: responsible_45gamblingkHrH49nFSCMeta || {},
    alias: responsible_45gamblingkHrH49nFSCMeta?.alias || [],
    redirect: responsible_45gamblingkHrH49nFSCMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/responsible-gambling.vue").then(m => m.default || m)
  },
  {
    name: self_45exclusion_45policyLcYpniCoCuMeta?.name ?? "self-exclusion-policy",
    path: self_45exclusion_45policyLcYpniCoCuMeta?.path ?? "/self-exclusion-policy",
    meta: self_45exclusion_45policyLcYpniCoCuMeta || {},
    alias: self_45exclusion_45policyLcYpniCoCuMeta?.alias || [],
    redirect: self_45exclusion_45policyLcYpniCoCuMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/self-exclusion-policy.vue").then(m => m.default || m)
  },
  {
    name: avatar9006ufSLE4Meta?.name ?? "signup-avatar",
    path: avatar9006ufSLE4Meta?.path ?? "/signup/avatar",
    meta: avatar9006ufSLE4Meta || {},
    alias: avatar9006ufSLE4Meta?.alias || [],
    redirect: avatar9006ufSLE4Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/signup/avatar.vue").then(m => m.default || m)
  },
  {
    name: registerhoQiI7lij7Meta?.name ?? "signup-register",
    path: registerhoQiI7lij7Meta?.path ?? "/signup/register",
    meta: registerhoQiI7lij7Meta || {},
    alias: registerhoQiI7lij7Meta?.alias || [],
    redirect: registerhoQiI7lij7Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/signup/register.vue").then(m => m.default || m)
  },
  {
    name: verificationAgbZPa0nt4Meta?.name ?? "signup-verification",
    path: verificationAgbZPa0nt4Meta?.path ?? "/signup/verification",
    meta: verificationAgbZPa0nt4Meta || {},
    alias: verificationAgbZPa0nt4Meta?.alias || [],
    redirect: verificationAgbZPa0nt4Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/signup/verification.vue").then(m => m.default || m)
  },
  {
    name: callback8OT7tj7rZHMeta?.name ?? "social-connect-provider-callback",
    path: callback8OT7tj7rZHMeta?.path ?? "/social/connect/:provider()/callback",
    meta: callback8OT7tj7rZHMeta || {},
    alias: callback8OT7tj7rZHMeta?.alias || [],
    redirect: callback8OT7tj7rZHMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/social/connect/[provider]/callback.vue").then(m => m.default || m)
  },
  {
    name: indexK5W8p2AsKcMeta?.name ?? "sports",
    path: indexK5W8p2AsKcMeta?.path ?? "/sports",
    meta: indexK5W8p2AsKcMeta || {},
    alias: indexK5W8p2AsKcMeta?.alias || [],
    redirect: indexK5W8p2AsKcMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditions3zS8mdD01YMeta?.name ?? "sports-terms-and-conditions",
    path: terms_45and_45conditions3zS8mdD01YMeta?.path ?? "/sports/terms-and-conditions",
    meta: terms_45and_45conditions3zS8mdD01YMeta || {},
    alias: terms_45and_45conditions3zS8mdD01YMeta?.alias || [],
    redirect: terms_45and_45conditions3zS8mdD01YMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/sports/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsQBgL6pni1YMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsQBgL6pni1YMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsQBgL6pni1YMeta || {},
    alias: terms_45and_45conditionsQBgL6pni1YMeta?.alias || [],
    redirect: terms_45and_45conditionsQBgL6pni1YMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: _91asset_93pCeLcVeAgIMeta?.name ?? "trading-asset",
    path: _91asset_93pCeLcVeAgIMeta?.path ?? "/trading/:asset()",
    meta: _91asset_93pCeLcVeAgIMeta || {},
    alias: _91asset_93pCeLcVeAgIMeta?.alias || [],
    redirect: _91asset_93pCeLcVeAgIMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/trading/[asset].vue").then(m => m.default || m)
  },
  {
    name: underage_45gaming_45policy3UYdRdU2PcMeta?.name ?? "underage-gaming-policy",
    path: underage_45gaming_45policy3UYdRdU2PcMeta?.path ?? "/underage-gaming-policy",
    meta: underage_45gaming_45policy3UYdRdU2PcMeta || {},
    alias: underage_45gaming_45policy3UYdRdU2PcMeta?.alias || [],
    redirect: underage_45gaming_45policy3UYdRdU2PcMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/underage-gaming-policy.vue").then(m => m.default || m)
  },
  {
    name: unsubscribemVivJBiRyaMeta?.name ?? "unsubscribe",
    path: unsubscribemVivJBiRyaMeta?.path ?? "/unsubscribe",
    meta: unsubscribemVivJBiRyaMeta || {},
    alias: unsubscribemVivJBiRyaMeta?.alias || [],
    redirect: unsubscribemVivJBiRyaMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: walletGEblUt4wKPMeta?.name ?? "wallet",
    path: walletGEblUt4wKPMeta?.path ?? "/wallet",
    meta: walletGEblUt4wKPMeta || {},
    alias: walletGEblUt4wKPMeta?.alias || [],
    redirect: walletGEblUt4wKPMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/wallet.vue").then(m => m.default || m)
  },
  {
    name: weekly_45raceXcxKUV4DFDMeta?.name ?? "weekly-race",
    path: weekly_45raceXcxKUV4DFDMeta?.path ?? "/weekly-race",
    meta: weekly_45raceXcxKUV4DFDMeta || {},
    alias: weekly_45raceXcxKUV4DFDMeta?.alias || [],
    redirect: weekly_45raceXcxKUV4DFDMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/weekly-race.vue").then(m => m.default || m)
  },
  {
    name: winnerszYDFTYGaZ0Meta?.name ?? "winners",
    path: winnerszYDFTYGaZ0Meta?.path ?? "/winners",
    meta: winnerszYDFTYGaZ0Meta || {},
    alias: winnerszYDFTYGaZ0Meta?.alias || [],
    redirect: winnerszYDFTYGaZ0Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/projects/main/pages/winners.vue").then(m => m.default || m)
  }
]