import { ALL_INFO_MODALS } from "@/sanity/gql";

export default defineNuxtPlugin((nuxtApp) => {
  const uiStore = useUiStore();
  const route = useRoute();
  const authStore = useAuthStore();

  nuxtApp.hook('app:mounted', () => {
    setTimeout(async() => {
      let welcomeModalHide = localStorage.getItem('welcomeModalHide3') === 'true';
      if (authStore.isUserLogged || uiStore.showConnectModal) {
        welcomeModalHide = true;
      }
      const welcomePromoModalHide = localStorage.getItem('welcomePromo150ethModalHide4') === 'true';

      if (route?.meta?.hideWelcomeModal || route.query.hidePopup) {
        uiStore.showInfoModal = false;
        return;
      }

      // Read stats as they're needed for all welcome modals
      if (!welcomePromoModalHide || !welcomeModalHide) {
        uiStore.infoModalStats = await nuxtApp.$api('/statistics/total', { method: 'GET', });
      } else {
        uiStore.showInfoModal = false;
        return;
      }

      // Read promo modal content from Sanity
      if (!welcomePromoModalHide && uiStore.infoModalStats) {
        const getInfoModal = nuxtApp.$useVillusQuery(ALL_INFO_MODALS, {id: "WelcomePromo"});
        const {data, error} = await getInfoModal();

        if (error) {
          nuxtApp.$rollbar.error('method failed', error);
        } else {
          const cmsData = data.allInfoModals[0];
          if (cmsData?.heading) {
            uiStore.infoModalStats.cmsData = cmsData;
          }
        }
      }

      // Determine which modal to show
      if (!welcomePromoModalHide && uiStore.infoModalStats && uiStore.infoModalStats.cmsData) {
        uiStore.infoModalType = 'WelcomePromo150eth';
        uiStore.showInfoModal = true;
      } else if (!welcomeModalHide && uiStore.infoModalStats) {
        uiStore.infoModalType = 'Welcome';
        uiStore.showInfoModal = true;
      }
    }, 1000);
  });
});
